<template>
  <div class="ci-empty-state">
    <div class="ci-empty-state__container">
      <div class="ci-empty-state__icon">
        <slot name="icon">
          <RdxIcon icon="ri-file-paper-line" />
        </slot>
      </div>
      <p class="ci-empty-state__header">
        <slot name="header" />
      </p>
      <p class="ci-empty-state__description">
        <slot name="description" />
      </p>
    </div>
  </div>
</template>

<script>
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";
export default {
  name: "CiEmptyState",
  components: {
    RdxIcon
  }
};
</script>

<style lang="scss">
.ci-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  height: 50%;

  &__container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__icon {
    display: inline;
    color: $grey-5;
    font-size: 40px;
    > * {
      line-height: 1;
    }
  }

  &__header {
    font-weight: bold;
    color: $grey-9;
    font-size: 14px;
    line-height: 20px;
  }

  &__description {
    color: $grey-7;
    font-size: 12px;
    line-height: 17px;
  }
}
</style>
