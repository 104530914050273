<template>
  <div class="view-button-sets p-3">
    <div class="p-3">
      <div class="view-button-sets__header">
        <CiHeading level="1">
          {{ $t("button-sets.header") }}
        </CiHeading>
        <RdxButton
          filled
          variant="blue"
          :to="{ name: 'CarsInventoryButtonSetNew' }"
        >
          <span>{{ $t("button-sets.create-set") }}</span>
        </RdxButton>
      </div>
      <RdxDivider class="mb-5" />

      <div class="row">
        <div class="col-12 col-lg-6 col-xl-3">
          <RdxInfo>
            {{ $t("button-sets.description-1") }}
          </RdxInfo>
          <RdxInfo>
            {{ $t("button-sets.description-2") }}
          </RdxInfo>
        </div>
        <div class="col-12 col-lg-6 col-xl-9">
          <div class="col-xl-9 p-0">
            <div
              v-if="loading"
              class="ci-empty-state view-button-sets__empty-state"
              data-cy="button-sets-list-loader"
            >
              <RdxLoader size="large" />
            </div>
            <div v-else-if="sets.length">
              <div class="button-sets-list" data-cy="button-sets-list">
                <div class="button-set-item button-set-item__header">
                  <div class="button-set-item__name">
                    {{ $t("button-sets.name") }}
                  </div>
                  <div class="button-set-item__created">
                    {{ $t("button-sets.created") }}
                  </div>
                  <div class="button-set-item__actions" />
                </div>
                <div v-for="set of sets" :key="set.id" class="button-set-item">
                  <div
                    class="button-set-item__name"
                    @click="editButtonSet(set.id)"
                  >
                    {{ set.attributes.name }}
                  </div>
                  <div class="button-set-item__created">
                    {{
                      $format.date(set.attributes.created_at, $i18n.locale())
                    }}
                  </div>
                  <div class="button-set-item__actions">
                    <RdxDropdown
                      v-tooltip="$t('button-sets.options')"
                      side="right"
                      class="mr-3 button-set-item__dropdown"
                    >
                      <RdxDropdownItem @click="duplicateButtonSet(set.id)">
                        <RdxIcon class="ri-file-copy-line rdx-icon--16" />
                        <span>
                          {{ $t("global.duplicate") }}
                        </span>
                      </RdxDropdownItem>
                      <RdxDropdownItem @click="deleteAction(set.id)">
                        <RdxIcon
                          class="color-red ri-delete-bin-6-line rdx-icon--16"
                        />
                        <span class="color-red">
                          {{ $t("global.delete") }}
                        </span>
                      </RdxDropdownItem>
                    </RdxDropdown>
                  </div>
                </div>
              </div>
            </div>
            <CiEmptyState v-else class="view-button-sets__empty-state">
              <template #header>
                {{ $t("button-sets.empty-state.header") }}
              </template>
              <template #description>
                <p class="pt-1 mb-3">
                  {{ $t("button-sets.empty-state.description") }}
                </p>
                <RdxButton
                  :outline="true"
                  :to="{ name: 'CarsInventoryButtonSetNew' }"
                >
                  {{ $t("button-sets.create-set") }}
                </RdxButton>
              </template>
            </CiEmptyState>
          </div>
        </div>
      </div>
    </div>
    <CiDeleteModal
      v-if="deleteModal.open"
      :heading="$t('button-sets.remove-modal.header')"
      :paragraph="$t('button-sets.remove-modal.description')"
      :delete-text="$t('button-sets.remove-modal.confirm-button')"
      :cancel-text="$t('global.cancel')"
      @delete="deleteSet"
      @cancel="hideModal"
    />
    <CiWarningModal
      v-if="warningModalOpen"
      :heading="$t('button-sets.block-remove-modal.header')"
      @cancel="closeWarningModal"
    >
      {{ $t("button-sets.block-remove-modal.description") }}
      <ul>
        <li
          v-for="list of buttonSetListing"
          :key="`publication-list-${list.id}`"
        >
          <router-link
            :to="{
              name: 'CarsInventoryEditList',
              params: { id: list.id }
            }"
            target="_blank"
          >
            {{ list.attributes.title }}
          </router-link>
        </li>
      </ul>
      {{ $t("button-sets.block-remove-modal.tip") }}
      <template #confirm><span></span></template>
    </CiWarningModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  RdxInfo,
  RdxDivider,
  RdxButton,
  RdxDropdown,
  RdxIcon,
  RdxDropdownItem,
  RdxLoader
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";
import CiEmptyState from "@/components/molecules/CiEmptyState";
import CiDeleteModal from "@/components/CiDeleteModal/CiDeleteModal";
import CiWarningModal from "@/components/CiWarningModal/CiWarningModal";

export default {
  name: "ButtonSetList",
  components: {
    RdxInfo,
    CiHeading,
    RdxDivider,
    RdxButton,
    CiEmptyState,
    RdxIcon,
    RdxDropdownItem,
    RdxDropdown,
    RdxLoader,
    CiDeleteModal,
    CiWarningModal
  },

  data() {
    return {
      deleteModal: {
        open: false,
        setToDelete: null
      },
      warningModalOpen: false,
      blockingSet: null,
      listings: [],
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      sets: "buttonSet/getAll",
      getById: "buttonSet/getById",
      locale: "location/getLocale"
    }),

    buttonSetListing() {
      let buttonSetListingsIds = this.blockingSet.relationships.listings.data
        .filter(({ type }) => type === "listing")
        .map(({ id }) => id);
      return this.listings.filter(({ id }) => {
        return buttonSetListingsIds.includes(id);
      });
    }
  },

  watch: {
    sets(newValue, oldValue) {
      if (!oldValue.length) this.loading = false;
    }
  },

  mounted() {
    this.fetchButtonSet();
    if (this.sets.length) this.loading = false;
  },

  methods: {
    deleteAction(id) {
      const buttonSet = this.getById(id);
      if (this.hasListingInRelation(buttonSet)) {
        this.blockingSet = buttonSet;
        this.warningModalOpen = true;
      } else {
        this.deleteModal.setToDelete = id;
        this.deleteModal.open = true;
      }
    },

    deleteSet() {
      this.$store
        .dispatch("buttonSet/delete", this.deleteModal.setToDelete)
        .then(() => {
          this.$toast.info(
            this.$t("button-sets.button-set-removed-successfull")
          );
        });
    },

    hasListingInRelation({ relationships }) {
      if (relationships && relationships.listings) {
        return Boolean(relationships.listings.data.length);
      } else {
        return false;
      }
    },

    hideModal() {
      this.deleteModal.open = false;
    },
    editButtonSet(id) {
      this.$router.push({
        name: "CarsInventoryButtonSetEdit",
        params: { id }
      });
    },

    duplicateButtonSet(id) {
      this.$router.push({
        name: "CarsInventoryButtonSetDuplicate",
        params: { id }
      });
    },

    closeWarningModal() {
      this.fetchButtonSet();
      this.warningModalOpen = false;
    },

    fetchButtonSet() {
      this.$store.dispatch("buttonSet/fetchAll").then(({ included }) => {
        this.listings = included.filter(({ type }) => type === "listing");
      });
    }
  }
};
</script>

<style lang="scss">
.view-button-sets {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__empty-state {
    margin-top: 8rem;
  }
}

.color-red {
  color: $red-5 !important;
}

.button-sets-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button-set-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $grey-5;
  background-color: $grey-1;
  padding: 22px 0;
  color: $grey-9;

  &:hover {
    background-color: $grey-2;
    cursor: pointer;
  }
  &__name {
    flex-grow: 3;
    width: 55%;
    padding: 0 16px;
    &:hover {
      color: $blue-6;
    }
  }
  &__created {
    flex-grow: 2;
    width: 25%;
    padding: 0 16px;
  }
  &__actions {
    flex-grow: 1;
    width: 10%;
    padding: 0 16px;
  }
  &__header {
    font-weight: bold;
    &:hover {
      cursor: default;
      background-color: $grey-1;
    }
    .button-set-item__name:hover {
      color: $grey-9;
    }
  }
  &__dropdown {
    z-index: unset;
  }
}
</style>
