<script>
export default {
  name: "CiHeading",
  props: {
    level: {
      type: [Number, String],
      required: true,
      default(level) {
        return [1, 2, 3].includes(level);
      }
    }
  },

  computed: {
    classes() {
      return ["ci-heading", [`ci-heading--${this.level}`]];
    }
  },

  render: function(createElement) {
    return createElement(
      "h" + this.level,
      {
        class: this.classes
      },
      this.$slots.default
    );
  }
};
</script>

<style lang="scss">
.ci-heading {
  display: block;

  &--1 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: $grey-10;
  }

  &--2 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: $grey-9;
  }

  &--3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: $grey-8;
  }

  &--details {
    font-size: 16px;
    color: $grey-10;
    margin-bottom: 15px;
  }
}
</style>
