<!-- eslint-disable vue/no-v-html -->
<template>
  <RdxModal>
    <div class="ci-delete-modal">
      <div class="ci-delete-modal__header">
        <RdxIcon
          icon="ri-delete-bin-line"
          class="rdx-icon--20 rdx-icon--red ci-delete-modal__header-icon"
        />
        <CiHeading level="1">{{ heading }}</CiHeading>
      </div>
      <div class="ci-delete-modal__main">
        <p class="ci-delete-modal__paragraph" v-html="paragraph" />
        <RdxInput
          v-if="withConfirmation > 1"
          v-model="confirmationWord"
          label='Type "Delete" to confirm'
          class="ci-delete-modal__input"
        />
        <p
          v-if="confirmationWord && !confirmationWordValid"
          class="ci-delete-modal__warning"
        >
          Wrong confirmation word
        </p>
        <div class="ci-delete-modal__actions">
          <RdxButton outline class="ci-delete-modal__button" @click="cancel">
            {{ cancelText }}
          </RdxButton>
          <RdxButton
            filled
            variant="red"
            :disabled="deleteButtonDisabled"
            class="ci-delete-modal__button"
            @click="deleteVehicles"
          >
            {{ deleteText }}
          </RdxButton>
        </div>
      </div>
    </div>
  </RdxModal>
</template>

<script>
import {
  RdxButton,
  RdxIcon,
  RdxModal,
  RdxInput
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

export default {
  name: "CiDeleteModal",

  components: {
    RdxButton,
    RdxIcon,
    RdxModal,
    RdxInput,
    CiHeading
  },

  props: {
    withConfirmation: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: "Remove item?"
    },
    paragraph: {
      type: String,
      default: "This will delete item. Note that this action cannot be undone."
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    deleteText: {
      type: String,
      default: "Delete"
    }
  },

  data() {
    return {
      confirmationWord: ""
    };
  },

  computed: {
    confirmationWordValid() {
      return this.confirmationWord
        ? this.confirmationWord.toLowerCase() === "delete"
        : false;
    },

    deleteButtonDisabled() {
      return !this.confirmationWordValid && this.withConfirmation > 1;
    }
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    deleteVehicles() {
      this.$emit("delete");
      this.cancel();
    }
  }
};
</script>

<style lang="scss">
.ci-delete-modal {
  padding: 32px;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__header-icon {
    margin-right: 15px;
  }

  &__main {
    padding-left: 35px;
  }

  &__paragraph {
    margin-bottom: 24px;
  }

  &__input {
    margin-bottom: 5px;
  }

  &__input .r &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__header-icon {
    margin-right: 15px;
  }

  &__main {
    padding-left: 35px;
  }

  &__paragraph {
    margin-bottom: 24px;
  }

  &__input {
    margin-bottom: 5px;
  }

  &__input .rdx-input__label {
    color: $grey-9;
  }

  &__warning {
    color: $red-6;
  }

  &__actions {
    margin-top: 24px;
    text-align: right;
  }

  &__button {
    margin-left: 5px;
  }
  dx-input__label {
    color: $grey-9;
  }

  &__warning {
    color: $red-6;
  }

  &__actions {
    margin-top: 24px;
    text-align: right;
  }

  &__button {
    margin-left: 5px;
  }
}
</style>
