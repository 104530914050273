<template>
  <div class="discounts-view p-3">
    <div class="new-vehicle-view__header p-4 py-2">
      <div style="display: flex; align-items: center">

        <CiHeading level="1" class="ml-2">Discounts Management</CiHeading>

      </div>
    </div>
    <div class="p-3">
      <RdxDivider class="mb-5" />

      <div class="row">
        <div class="col-sm-3">
          Discount activated for following models Land Rover (comma seperated)
        </div>
        <div class="col-sm-9">

          <VRdxInput
            v-model="landrover_onsale"
            :pending="pending.load || pending.save"
            rules="required"
          />


          <RdxButton
            filled
            :disabled="pending.load"
            :loading="pending.save"
            @click="saveForm(1)"
          >
            {{ $t("global.save") }}
          </RdxButton>
        </div>

      </div>
      <RdxDivider class="mb-5" />

      <div class="row">
        <div class="col-sm-3">
          Discount activated for following models Jaguar (comma seperated)
        </div>
        <div class="col-sm-9">

          <VRdxInput
            v-model="jaguar_onsale"
            :pending="pending.load || pending.save"
            rules="required"
          />


          <RdxButton
            filled
            :disabled="pending.load"
            :loading="pending.save"
            @click="saveForm(2)"
          >
            {{ $t("global.save") }}
          </RdxButton>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import { RdxDivider } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

import { withValidation } from "vee-validate";
import { RdxIcon, RdxButton,RdxInput } from "@raffine/rdx-ui/lib/rdx-ui.umd";
const VRdxInput = withValidation(RdxInput, ({ errors }) => ({ errors }));

import payloadRewriter from "@/utils/payload-rewriter";
import { customAtob } from "@/store/base64";
export default {
  name: "CarsInventoryDiscounts",

  components: {
    RdxDivider,
    RdxInput,
    VRdxInput,
    RdxButton,
    CiHeading
  },

  data() {
    return {
      pending: {
        load: false,
        save: false
      },
      jaguar_onsale: "",
      landrover_onsale: ""

    };
  },

  created() {
    this.getDiscounts();
  },

  methods: {
    saveForm(brand) {
      this.pending.save = true;
      let modelstr = '';
      if (brand === 1){
        modelstr = this.landrover_onsale;
      }
      else if (brand === 2){
        modelstr = this.jaguar_onsale;
      }
      const params = {
        data: {
          attributes: {
            models_onsale: modelstr
          }
        }
      };

      this.$http
        .patch("brands/"+brand, params)
        .then(() => {
          this.$toast.success(this.$t("settings.succesfully-updated"));
        })
        .finally(() => (this.pending.save = false));

    },
    getDiscounts() {
      this.pending.load = true;

      this.$http
        .get("/brands/1")
        .then(response => {
          this.landrover_onsale = response.data.data.attributes.models_onsale;
        })
        .then(() => {
          this.pending.load = false;
        });
      this.$http
        .get("/brands/2")
        .then(response => {
          this.jaguar_onsale = response.data.data.attributes.models_onsale;
        })
        .then(() => {
          this.pending.load = false;
        });
    },

    isCurrentRoute(routeName) {
      const { name } = this.$route;
      return !!(name === routeName);
    }
  }
};
</script>
