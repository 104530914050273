export default function payloadRewriter(obj, payload) {
  if (!obj || !payload) {
    console.error(
      `CI :: Missing "obj" or "payload" params in "payloadRewriter"`
    );
    return false;
  }

  Object.keys(obj).forEach(key => {
    if (Object.keys(payload).includes(key)) {
      obj[key] = payload[key];
    } else {
      console.error(`CI :: Cannot find "${key}" key in API response`);
    }
  });
}
