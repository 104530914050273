<!-- eslint-disable vue/no-v-html -->
<template>
  <RdxModal>
    <div class="ci-warning-modal">
      <div class="ci-warning-modal__header">
        <RdxIcon
          :icon="icon"
          class="rdx-icon--20 rdx-icon--yellow ci-warning-modal__header-icon"
        />
        <CiHeading level="1">{{ heading }}</CiHeading>
      </div>
      <div class="ci-warning-modal__main">
        <slot>
          <p class="ci-warning-modal__paragraph" v-html="paragraph" />
        </slot>
        <div class="ci-warning-modal__actions">
          <slot name="cancel">
            <RdxButton outline class="ci-warning-modal__button" @click="cancel">
              {{ cancelLabel || $t("global.cancel") }}
            </RdxButton>
          </slot>
          <slot name="confirm">
            <RdxButton filled class="ci-warning-modal__button" @click="confirm">
              {{ confirmLabel || $t("global.delete") }}
            </RdxButton>
          </slot>
        </div>
      </div>
    </div>
  </RdxModal>
</template>

<script>
import { RdxButton, RdxIcon, RdxModal } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

export default {
  name: "CiDeleteModal",

  components: {
    RdxButton,
    RdxIcon,
    RdxModal,
    CiHeading
  },

  props: {
    icon: {
      type: String,
      default: "ri-error-warning-line"
    },
    heading: {
      type: String,
      default: ""
    },
    paragraph: {
      type: String,
      default: ""
    },
    cancelLabel: {
      type: String,
      default: ""
    },
    confirmLabel: {
      type: String,
      default: ""
    }
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
      this.cancel();
    }
  }
};
</script>

<style lang="scss">
.rdx-modal {
  position: fixed;
}

.ci-warning-modal {
  padding: 32px;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__header-icon {
    margin-right: 15px;
  }

  &__main {
    padding-left: 35px;
    ul {
      margin: 0.5rem 0 0.5rem 1rem;
    }
  }

  &__paragraph {
    margin-bottom: 24px;
  }

  &__input {
    margin-bottom: 5px;
  }

  &__input .r &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__input .rdx-input__label {
    color: $grey-9;
  }

  &__warning {
    color: $red-6;
  }

  &__actions {
    margin-top: 24px;
    text-align: right;
  }

  &__button {
    margin-left: 5px;
  }
}
</style>
